import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FullComponent } from "./layouts/full/full.component";
import { BlankComponent } from "./layouts/blank/blank.component";
import { SidebarComponent } from "./shared/sidebar/sidebar.component";
import { SharedModule } from "./shared/shared.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { cHeadersInterceptor } from "src/interceptors/headers.interceptor";
import { cLoaderInterceptor } from "src/interceptors/loader.interceptor";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { SortingService } from "./services/sorting.service";
import { CommonModule } from "@angular/common";
import { CloudComponent } from "./modules/cloud/cloud.component";

@NgModule({
  declarations: [AppComponent, FullComponent, BlankComponent,CloudComponent],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    NgbModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: cHeadersInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: cLoaderInterceptor,
      multi: true,
    },
    SortingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
