import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { cDataService } from 'src/app/services/data.service';
import { cSessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-navigation-buttons',
  templateUrl: './navigation-buttons.component.html',
  styleUrls: ['./navigation-buttons.component.scss']
})
export class NavigationButtonsComponent {
  docState: any;
  matching: any;
  viewBtn: boolean;
  oUserDetails: any
  sSelectedFolderItem: any;
  activeBrowseDocs: boolean = false;
  sharedButtons: any = {
    documents: false,
    matching: false,
    bulkUpload: false,
  }
  isSchemasRoute: boolean = false;

  constructor(
    private oDataService: cDataService,
    private oSessionService: cSessionService,
    private oRoute: Router

  ) {
    this.oRoute.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.isSchemasRoute = event.url === '/doc-manager/schemas';
        if (this.isSchemasRoute) {
          Object.assign(this.sharedButtons, { bulkUpload: false, documents: false, matching: false });
        }
      });

  }

  ngOnInit(): void {
    this.oUserDetails = this.oSessionService.SessionService_GetUserDetails();
    this.oDataService.headerViewBtn.subscribe((res) => {
      let docStates = JSON.parse(localStorage.getItem("docState"));
      this.sharedButtons = docStates;
      this.viewBtn = res;
    });
    this.oDataService.activeBrowseDocs.subscribe((res) => {
      this.activeBrowseDocs = res;
    });
  }
  onButtonClick(buttonType: string) {
    Object.keys(this.sharedButtons).forEach(key => {
      this.sharedButtons[key] = false;
    });
    this.sharedButtons[buttonType] = true;
    this.setState();
    this.oRoute.navigate(['/doc-manager'])
    if (buttonType === 'documents') {
      this.oDataService.switchToDocuments.next(true);
      this.oDataService.activeBrowseDocs.next(true);
    } else {
      this.oDataService.activeBrowseDocs.next(false);
    }
    if (buttonType === 'matching') {
      this.oDataService.globalSearch.next({ folderId: '', searchBoolan: false });
    } else if (buttonType === 'home') {
      localStorage.removeItem('searchQuery');
      this.oDataService.resetSearch.next(true);
      this.oDataService.newConversation.next(true);
    }
    this.oDataService.headerViewBtn.next(true);
  }



  setState() {
    localStorage.setItem("docState", JSON.stringify(this.sharedButtons));
    this.sSelectedFolderItem = this.oSessionService.cSessionService_GetDocumentManagerSelectedItem();
    this.oDataService.globalSearch.next({
      folderId: this.sSelectedFolderItem?.id,
      searchBoolan: this.docState,
    });
  }
}
