

<app-alert-messages
  *ngIf="bDisplayErrorBlock"
  [resMessage]="resMessage"
></app-alert-messages>
<div class="authentication-body">
    <div class="zoom-in-out-box">
        <div class="error-box">
            <div class="error-body text-center">
                <div class="d-flex justify-content-center align-items-center loaderHeight">
                    <div class="d-inline">
                            <span *ngIf="bLoading" class="loader"></span>
                            <span *ngIf="sResponseMessage != ''" class="text">{{sResponseMessage}}</span>
                        <div>
                            <a (click)="NotFoundComponent_PreviousPath()" class="btn btn-danger not-found-error-btn btn-rounded waves-effect waves-light m-b-40 text-white">Back to home</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>