<app-alert-messages *ngIf="bDisplayErrorBlock" [resMessage]="resMessage"></app-alert-messages>
<div class="head">
  <div class="bg-white p-3 head-content d-flex align-items-center">
    <div class="logo-container bg-white">
      <button *ngIf="UserDetails.role == 'admin' || UserDetails.role == 'editor'  || UserDetails?.role == 'userSFTP' && ( menuItems?.menus[0]?.label == 'Trillo File Manager' &&  UserDetails?.role == 'userSFTP')" class="logo d-flex align-self-end p-0"
        (click)="SidebarComponent_ToggleCollapse()">
        <i style="height: 24px; width: 24px" class="fa-solid fa-bars"></i>
      </button>
      <div class="logo-text" [class.m-0]="userDetails.role == 'user'">
        <img *ngIf="showLogo && environment == 'trillo'" class="cursor-pointer" [src]="
            configFileLogo
              ? this?.configFileLogo?.status == 'failed' ? '../../../assets/images/logo-text-dark.png' : 'data:image/png;base64,' + logo
              : '../../../assets/images/logo-text-dark.png'
          " height="30" alt="" (click)="HeaderComponent_GoTopreviousPage()" />
        <img *ngIf="showLogo && environment == 'kidm'" class="cursor-pointer kidm-logo" [src]="
            configFileLogo
            ? this?.configFileLogo?.status == 'failed' ? '../../../assets/images/kidm-logo.png' : 'data:image/png;base64,' + logo
              : '../../../assets/images/kidm-logo.png'
          " height="30" alt="" (click)="HeaderComponent_GoTopreviousPage()" />
        <img *ngIf="showLogo && environment != 'trillo' && environment != 'kidm'" class="cursor-pointer" [src]="
          configFileLogo
            ? this?.configFileLogo?.status == 'failed' ? '../../../assets/images/logo-text-dark.png' : 'data:image/png;base64,' + logo
            : '../../../assets/images/logo-text-dark.png'
        " height="30" alt="" (click)="HeaderComponent_GoTopreviousPage()" />
      </div>
    </div>
    <!-- global search -->
    <div *ngIf="bDisplayGlobalSearches" class="global-search d-flex gap-2">
      <div class="input-group">
        <input type="text" class="form-control input-group-search"
          placeholder="Enter a search phrase or question"
          (ngModelChange)="onSearchDocumentResultChange($event)" [(ngModel)]="searchDocumentResult"
          (keydown.enter)="HeaderComponent_NavigateToSearchDashboard()" />
        <i class="fa-regular fa-magnifying-glass"></i>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block">
      <div ngbDropdown class="d-inline-block" *ngIf="bShowIcon">
        <div class="usermanager-dropdown icons-container" ngbDropdownToggle>
          <i class="fa-solid fa-ellipsis-vertical"></i>
        </div>

        <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
          <button (click)="HeaderComponent_hideHeaderSearch()" ngbDropdownItem routerLink="/groups">
            <i class="fa-light fa-people-group me-2"></i>
            Groups
          </button>
          <button (click)="HeaderComponent_hideHeaderSearch()" class="border-bottom" ngbDropdownItem
            routerLink="/usermanager/roles">
            <i class="fa-sharp fa-regular fa-user-gear me-2"></i>
            Roles
          </button>
        </div>
      </div>
    </div>

    <!-- search  -->
    <div class="input-wrapper" *ngIf="bShowSearchIcon">
      <button class="icon">
        <i class="fa-solid fa-magnifying-glass"></i>
      </button>
      <input placeholder="search" class="input" name="text" type="text" [(ngModel)]="searchResult"
        (keyup.enter)="HeaderComponent_FilterData()" />
    </div>
    <!-- search end -->
    
    <div class="d-flex align-item-center">
      <app-navigation-buttons></app-navigation-buttons>
      <div ngbDropdown class="d-inline-block">
        <div class="profile-dropdown" ngbDropdownToggle>
          <i class="fa-light fa-user"></i>
        </div>

        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <div class="header-user-role border-bottom">
            <button class="d-flex align-items-center align-item-center no-hover" ngbDropdownItem>
              <div class="profile-dropdown mr-2">
                <span>{{
                  UserDetails?.fullName
                  ? UserDetails?.fullName[0]
                  : UserDetails?.userId[0]
                  }}</span>
              </div>
              <div>
                {{
                UserDetails?.fullName && UserDetails?.fullName.length <= 15 ? UserDetails?.fullName :
                  UserDetails?.userId }} <p class="user-role mb-0">
                  {{ UserDetails.role == "*" ? "Any Role" : UserDetails.role }}
                  </p>
              </div>
            </button>
          </div>
          <button class="pl-25 font-15" ngbDropdownItem (click)="HeaderComponent_OpenEditprofile(editProfile)">
            <i class="fa-light fa-user"></i>
            My Profile
          </button>
          <button class="border-bottom pl-25 font-15" ngbDropdownItem
            (click)="HeaderComponent_ChangePassword(changePassword)">
            <i class="fa-light fa-gear"></i>
            Change Password
          </button>
          <button *ngIf="bDisplayTenantInformation" class="border-bottom pl-25 font-15" ngbDropdownItem
            (click)="HeaderComponent_TenantInformation(tenantInformation)">
            <i class="fa-duotone fa-circle-info"></i>
            Tenant Information
          </button>
          <button class="border-bottom pl-25 font-15" ngbDropdownItem
            (click)="HeaderComponent_VersionInformation(versionInfo)">
            <i class="fa-light fa-circle-info me-1"></i>
            About
          </button>
          <button class="pl-25 font-15" ngbDropdownItem (click)="HeaderComponent_Logout()">
            <i class="fa-light mb-2 fa-arrow-right-from-bracket"></i>
            Logout
          </button>
        </div>
      </div>
      <div ngbDropdown class="d-inline-block header-dropdown ml-2" *ngIf="
          showEllipses &&
          (UserDetails.role == 'admin' || UserDetails.role == 'editor')
        ">
        <div class="usermanager-dropdown icons-container" ngbDropdownToggle>
          <i class="fa-solid fa-ellipsis-vertical"></i>
        </div>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
          <button ngbDropdownItem (click)="HeaderComponent_hideHeaderSearch()" routerLink="doc-manager/schemas">
            <i class="fa-sharp fa-thin fa-network-wired me-2"></i>
            Schemas
          </button>
          <button ngbDropdownItem (click)="HeaderComponent_hideHeaderSearch()" routerLink="/groups">
            <i class="fa-light fa-people-group me-2"></i>
            Groups
          </button>
          <button class="border-bottom" (click)="HeaderComponent_hideHeaderSearch()" ngbDropdownItem
            routerLink="/settings">
            <i class="fa-light fa-gear me-1"></i>
            Settings
          </button>
          <div *ngIf="
              UserDetails.role == '*' ||
              UserDetails.role == 'admin' ||
              UserDetails.role == 'editor'
            ">
            <button (click)="HeaderComponent_hideHeaderSearch()" ngbDropdownItem routerLink="/usermanager">
              <i class="fa-light fa-user me-1"></i>
              Admin
            </button>
            <button (click)="HeaderComponent_hideHeaderSearch()" class="border-bottom" ngbDropdownItem
              routerLink="/usermanager/roles">
              <i class="fa-sharp fa-regular fa-user-gear me-2"></i>
              Roles
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #editProfile let-modal>
  <div class="modal-header">
    <h4 class="modal-title modal-header-font">Edit My Profile</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="HeaderComponent_DismissModal()"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="changeNameForm">
      <div class="form-row">
        <div class="form-group col-md-12 mb-3">
          <label class="form-label" for="Userid">User ID</label>
          <input type="text" class="form-control readOnly" id="Userid" placeholder="User ID" formControlName="userId"
            readonly />
        </div>
        <div class="form-group col-md-12 mb-3">
          <label class="form-label" for="firstname">First Name</label>
          <input type="text" class="form-control" id="firstname" placeholder="First Name" formControlName="firstname" />
          <small *ngIf="
              changeNameForm.controls['firstname'].hasError('required') &&
              changeNameForm.controls['firstname'].touched
            " class="text-danger">First Name is required</small>
          <small *ngIf="changeNameForm.controls['firstname'].hasError('pattern')" class="text-danger">First character
            cannot be space
          </small>
        </div>
        <div class="form-group col-md-12 mb-3">
          <label class="form-label" for="lastname">Last Name</label>
          <input type="text" class="form-control" id="lastname" placeholder="Last Name" formControlName="lastname" />
          <small *ngIf="
              changeNameForm.controls['lastname'].hasError('required') &&
              changeNameForm.controls['lastname'].touched
            " class="text-danger">Last Name is required</small>
          <small *ngIf="changeNameForm.controls['lastname'].hasError('pattern')" class="text-danger">First character
            cannot be space
          </small>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="text-right">
      <button type="button" class="btn btn-outline-secondary" (click)="HeaderComponent_DismissModal()">
        Cancel
      </button>
      <button type="button" class="btn btn-primary ml-2" (click)="HeaderComponent_changeName()">
        Submit
      </button>
    </div>
  </div>
</ng-template>

<ng-template #changePassword let-modal>
  <div class="modal-header">
    <h4 class="modal-title modal-header-font">Change Password</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="HeaderComponent_DismissModal()"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="changePasswordForm">
      <div class="form-row">
        <div class="form-group col-md-12 mb-3">
          <label class="form-label" for="Userid">User ID</label>
          <input type="text" class="form-control readOnly" id="Userid" placeholder="User ID" formControlName="userId"
            readonly />
        </div>
        <div class="form-group col-md-12 mb-3">
          <label class="form-label" for="Currentpassword">Current password</label>
          <input type="password" class="form-control" id="Currentpassword" placeholder="Current password"
            formControlName="oldPassword" />
          <small *ngIf="
              changePasswordForm.controls['oldPassword'].hasError('required') &&
              changePasswordForm.controls['oldPassword'].touched
            " class="text-danger">Password is required</small>
        </div>
        <div class="form-group col-md-12 mb-3">
          <label class="form-label" for="Newpassword">New Password</label>
          <input type="password" class="form-control" id="Newpassword" placeholder="New Password"
            formControlName="password" />
          <small *ngIf="
              changePasswordForm.controls['password'].hasError('required') &&
              changePasswordForm.controls['password'].touched
            " class="text-danger">New password is required</small>
          <small *ngIf="
              changePasswordForm.controls['password'].hasError('pattern') &&
              changePasswordForm.controls['password'].touched
            " class="text-danger">Password needs to be at least 8 characters long.</small>
        </div>
        <div class="form-group col-md-12 mb-3">
          <label class="form-label" for="Repeatpassword">Confirm New Password</label>
          <input type="password" class="form-control" id="Repeatpassword" placeholder="Confirm New Password"
            formControlName="rptPassword" />
          <small *ngIf="
              changePasswordForm.controls['rptPassword'].hasError('required') &&
              changePasswordForm.controls['rptPassword'].touched
            " class="text-danger">Confirm new password is required</small>
          <small
            *ngIf="changePasswordForm.controls['rptPassword'].errors?.['mustMatch'] && changePasswordForm.controls['rptPassword'].dirty"
            class="text-danger">Password and confirm new password not matched</small>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="text-right">
      <button type="button" class="btn btn-outline-secondary" (click)="HeaderComponent_DismissModal()">
        Cancel
      </button>
      <button type="button" class="btn btn-primary ml-2" (click)="HeaderComponent_changePassword()">
        Submit
      </button>
    </div>
  </div>
</ng-template>
<ng-template #tenantInformation let-modal>
  <div class="modal-header">
    <h4 class="modal-title modal-header-font">Tenant Information</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="HeaderComponent_DismissModal()"></button>
  </div>
  <div class="modal-body">
    <div class="row d-flex">
      <p class="sub-heading mb-0">
        Tenant ID:
        <span class="font-600">{{ loginTenantInformation?.tenantId }}</span>
      </p>
      <p class="sub-heading">
        Tenant Name:
        <span class="font-600">{{ loginTenantInformation?.tenantName }}</span>
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="text-right">
      <button type="button" class="btn btn-outline-secondary" (click)="HeaderComponent_DismissModal()">
        Close
      </button>
    </div>
  </div>
</ng-template>
<ng-template #versionInfo let-modal>
  <div class="modal-header">
    <h4 class="modal-title modal-header-font">Build</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="HeaderComponent_DismissModal()"></button>
  </div>
  <div class="modal-body">
    <div class="row d-flex">
      <!-- <h5>Build:</h5> -->
      <p class="sub-heading mb-0">
        Version:
        <span class="font-600">{{ versionInformation.BuildVersion }}</span>
      </p>
      <p class="sub-heading mb-0">
        Date: <span class="font-600">{{ versionInformation.BuildDate }}</span>
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="text-right">
      <button type="button" class="btn btn-outline-secondary" (click)="HeaderComponent_DismissModal()">
        Close
      </button>
    </div>
  </div>
</ng-template>