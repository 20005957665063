import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }

  AuthService_Login(credentials): Observable<any> {
    return this.http.post(environment.BaseURL+'/ajaxLogin',credentials
);
  }
  AuthService_GetConfigurations(): Observable<any> 
  {
      return this.http.post(environment.BaseURL+'/ds/function/shared/ProjectConfig', {});
    // commented API to get confgs from bucket
    // return this.http.get<any>('https://storage.googleapis.com/'+environment.BucketName+'/groups/configs/configs.json');
  }
  AuthService_GetAvailableOrganizarionName(body): Observable<any> 
  {
      return this.http.get(environment.BaseURL+'/_preauthsvc/user/validateTenant?tenantName='+body);
  }
  AuthService_SendSignupRequest(body): Observable<any> 
  {
      return this.http.post(environment.BaseURL+'/_preauthsvc/user/signup',body);
  }
  AuthService_SendLoginRequest(body): Observable<any> 
  {
      return this.http.post(environment.BaseURL+'/_preauthsvc/user/authenticate',body);
  }

  AuthService_SelectTenant(body): Observable<any> 
  {
      return this.http.post(environment.BaseURL+'/_preauthsvc/user/selectTenant',body);
  }
  AuthService_SocialLogin(body) : Observable<any>
  {
    return this.http.post<any>(environment.BaseURL +  '/_oauth2/url' , body );
  }
  AuthService_GetATNonce(body) : Observable<any>
  {
    return this.http.get<any>(environment.BaseURL +  '/_oauth2/nonceLogin?a_t_nonce=' + body);
  }
  
  
  
}
