<div class="d-flex">
  <button
    *ngIf="viewBtn || isSchemasRoute"
    ngbTooltip="New Conversation"
    placement="bottom"
    class="btn btn-sm btn-default navigation-buttons me-2"
    [ngClass]="oUserDetails?.role !== 'user' ? '' : 'Home-btn-user'"
    [class.active-btn]="
      !sharedButtons?.documents && !sharedButtons?.matching && !isSchemasRoute
    "
    (click)="onButtonClick('home')"
  >
    <i class="fa-house fa-regular" aria-hidden="true"></i>
  </button>

  <button
    *ngIf="viewBtn || isSchemasRoute"
    ngbTooltip="Browse Documents"
    container="body"
    placement="bottom"
    class="btn btn-sm btn-default navigation-buttons me-2"
    [class.active-btn]="sharedButtons?.documents"
    (click)="onButtonClick('documents')"
  >
    <i class="fa-folder-tree fa-regular" aria-hidden="true"></i>
  </button>

  <button
    *ngIf="(oUserDetails?.role !== 'user' && viewBtn) || isSchemasRoute"
    class="btn btn-sm btn-default navigation-buttons search-button me-2"
    ngbTooltip="Matching"
    placement="left"
    [class.active-btn]="sharedButtons?.matching"
    (click)="onButtonClick('matching')"
  >
    <i class="fa-regular fa-equals"></i>
  </button>
</div>
